<template>
    <div>
        <!-- Provides the application the proper gutter -->
        <v-main class="mt-n12">
            <NavDrawer :mini="mini" @drawerToggled="mini = $event"/>
            <AppBar :drawer="drawer"/>
            <v-container class="mt-15" fluid>
                <router-view></router-view>
            </v-container>
        </v-main>
        <Footer/>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import Footer from "@/components/Footer.vue";

export default {
    name: "Main",
    components: {
        AppBar,
        NavDrawer,
        Footer,
    },
    data: () => ({
        drawer: true,
        mini: true,
        page: "Dashboard",
    }),
    created() {

        axios.interceptors.response.use(
            (res) => {
                return res;
            },
            (error) => {
                if (error.response.status == 401) {
                    localStorage.removeItem("user");
                    this.$store.dispatch('alert/success', "Session expired")
                    this.$router.push("/login");
                }
                return error;
            }
        );
    },
};
</script>
