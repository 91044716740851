<template>
  <div>
    <v-app-bar
      app
      :value="true"
      clipped-none
      :color="this.$vuetify.theme.dark ? 'black' : 'info'"
      elevation="0"
      class="border-bottom-2"
    >
      <v-app-bar-nav-icon
        class="ml-auto"
        color="white"
        @click="toggleDrawer(!mini)"
      >
      </v-app-bar-nav-icon>
      <v-spacer> </v-spacer>
      <span class="ml-200 secondary--text" color="blue-grey">
        <v-img
          :lazy-src="companyLogoUrl"
          :src="companyLogoUrl"
          max-width="100"
          max-height="100"
          class="mx-auto my-5"
        ></v-img>
      </span>

      <v-spacer> </v-spacer>

      <span class="mt-5 mr-3 text-right">
        <span class="font-weight-medium white--text caption">
          {{ user.name }}</span
        >
        <p class="white--text caption">{{ user.email }}</p>
      </span>

      <v-menu
        :offset-y="offset"
        left
        bottom
        class="mr-5"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" outlined id="main_app_bar_menu">
            <v-avatar v-if="!user.image_url" :color="selectedColor">
              <span class="white--text">{{ getInitials(user.name) }}</span>
            </v-avatar>
            <v-avatar v-else dark v-bind="attrs" v-on="on">
              <img
                :src="user.image_url"
                :lazy-src="user.image_url"
                alt="Profile Image"
                max-width="6"
                max-height="6"
              />
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link to="/complete-profile">
            <v-icon class="mr-2">mdi-domain</v-icon>
            <v-list-item-title>Complete Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="toggleTheme">
            <v-icon class="mr-2">mdi-video-switch-outline</v-icon>
            <v-list-item-title>{{ themeMode }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="handleLogout" id="logout_btn">
            <v-icon class="mr-2">mdi-logout-variant</v-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import OrganizationService from "./../services/organization.service";

export default {
  name: "AppBar",
  props: ["page"],
  data: () => ({
    offset: true,
    organizations: [],
    dummyVariable: null,
    user_organisation: [
      "Organisation 1",
      "Organisation 2",
      "Organisation 3",
      "Organisation 4",
    ],
    selectedColor: "",
    items: [],
    notifications: [
      {
        action: "15 min",
        headline: "",
        subtitle: `You can now proceed with your application for Tender Number ABC/1215/2020`,
        title: "Tender ABC/1215/2020 ",
      },
    ],
    colors: [
      "pink lighten-5",
      "blue-grey lighten-4",
      "teal lighten-4",
      "cyan lighten-4",
      "red lighten-4",
      "primary",
      "red lighten-3",
      "red accent-1",
      "indigo lighten-3",
      "teal accent-4",
    ],
  }),
  components: {},
  created() {
    // const idx = Math.floor(Math.random() * this.colors.length);
    // this.selectedColor = this.colors[idx];
    this.getOrgId();
  },
  computed: {
    mini() {
      return this.$store.state.mini;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    org() {
      return JSON.parse(localStorage.getItem("org"));
    },
    userData() {
      return JSON.parse(localStorage.getItem("userData"));
    },
    companyLogoUrl() {
      return this.$store.state.auth.user
        ? this.$store.state.auth.user.company
          ? this.$store.state.auth.user.company.company_logo_url
          : ""
        : "";
    },
    themeMode() {
      return !this.$vuetify.theme.dark ? "Dark Mode" : "Light Mode";
    },
    organisationOptions() {
      return this.getOrgs();
    },
  },
  methods: {
    getOrgId() {
      return OrganizationService.index().then(
        (response) => {
          // console.log(response);
          if (response.status == 200) {
            let result = response.data.data;
            this.items = result.map(function (element) {
              let user = {
                id: element.id,
                legal_name: element.legal_name,
                id_parent: element.id_parent,
                action: "",
              };
              return user;
            });
            // console.log(this.organizations);
            this.loading = false;
          } else {
            this.organizations = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.organizations = [];
          console.log(error);
        }
      );
    },
    toggleTheme() {
      this.$store.dispatch(
        "auth/setTheme",
        (this.$vuetify.theme.dark = !this.$vuetify.theme.dark)
      );
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    toggleDrawer(value) {
      this.$store.dispatch("minifyDrawer", value);
    },
    getInitials: function (str) {
      return str
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), "");
    },
  },
};
</script>
